<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mb-7">
        <v-card-title>
          Microgrid Control Setting : {{ siteInfo.site_name_en || null }}
        </v-card-title>

        <v-card-text>
          <v-alert class="mb-6" :type="alert.type" dismissible dense outlined v-if="alert.type !== ''">
            {{ alert.msg }}
          </v-alert>
          <v-form class="multi-col-validation" ref="form">
            <fieldset>
              <legend class="font-weight-bold">System Setting</legend>
              <v-row>
                <v-col cols="12" md="2">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.solar_state"
                        inset
                        color="primary"
                        label="Solar"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.solar_power"
                        outlined
                        dense
                        label="Rate Power (kW)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.batt_state"
                        inset
                        color="primary"
                        label="Battery"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.batt_power"
                        outlined
                        dense
                        label="Rate Power (kW)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.batt_capacity"
                        outlined
                        dense
                        label="Installed Capacity (kWh)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.discharge_soc"
                        outlined
                        dense
                        label="Discharging Cutoff SOC (%)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.charge_soc"
                        outlined
                        dense
                        label="Charging Cutoff SOC (%)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.gen_state"
                        inset
                        color="primary"
                        label="Generator"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.gen_power"
                        outlined
                        dense
                        label="Rate Power (kW)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.charge_power"
                        outlined
                        dense
                        label="Charge Power (%)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.frequency"
                        outlined
                        dense
                        label="Frequency (Hz)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </fieldset>

            <fieldset class="mt-5">
              <legend class="font-weight-bold">Battery Charging Condition | Normal Case</legend>
              <v-row>
                <v-col cols="12" md="2">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.time_condition"
                        inset
                        color="primary"
                        label="Time Condition"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <div class="mb-2">
                        Charging Period 1
                      </div>
                      <div class="d-flex ">
                        <v-text-field
                          v-model="configData.charge_start_p1"
                          outlined
                          dense
                          label="Start"
                          type="time"
                          class="me-2"
                        />
                        <v-text-field
                          v-model="configData.charge_stop_p1"
                          outlined
                          dense
                          label="End"
                          type="time"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <div class="mb-2">
                        Charging Period 2
                      </div>
                      <div class="d-flex ">
                        <v-text-field
                          v-model="configData.charge_start_p2"
                          outlined
                          dense
                          label="Start"
                          type="time"
                          class="me-2"
                        />
                        <v-text-field
                          v-model="configData.charge_stop_p2"
                          outlined
                          dense
                          label="End"
                          type="time"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.soc_condition"
                        inset
                        color="primary"
                        label="SOC Condition"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.soc_start"
                        outlined
                        dense
                        label="Start at (%)"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.soc_stop"
                        outlined
                        dense
                        label="Stop at (%)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </fieldset>

            <fieldset class="mt-5">
              <legend class="font-weight-bold">Battery Charging Condition | Abnormal Case</legend>
              <v-row>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-text-field
                    v-model="configData.fault_time_interval"
                    outlined
                    dense
                    label="Fault Detection Time Interval (s)"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-text-field
                    v-model="configData.runtim_backup"
                    outlined
                    dense
                    label="Minimum Runtime of Backup Source (s)"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-text-field
                    v-model="configData.restime_backup"
                    outlined
                    dense
                    label="Minimum Rest time of Backup Source (s)"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.over_voltage"
                        inset
                        color="primary"
                        label="Over Voltage Condition"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.over_voltage_at"
                        outlined
                        dense
                        label="Over Voltage at (V)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.under_voltage"
                        inset
                        color="primary"
                        label="Under Voltage Condition"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.under_voltage_at"
                        outlined
                        dense
                        label="Under Voltage at (V)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="configData.over_current"
                        inset
                        color="primary"
                        label="Over Current Condition"
                        hide-details
                        class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="configData.over_current_at"
                        outlined
                        dense
                        label="Over Current at (A)"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </fieldset>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-btn color="primary" @click="submitChange" :loading="isProcessing">
                  Save
                </v-btn>

                <span class="ms-4" :class="`${alert.type}--text`" v-if="alert.type !== ''">{{ alert.msg }}</span>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
fieldset {
  margin-top: 12px;
  border: 1px solid rgb(0, 180, 156);
  padding: 1.2rem;
  border-radius: 8px;
}
legend {
  padding: 0 0.5rem;
}
</style>
<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required } from '@core/utils/validation'
import {
  ref, reactive, computed, onMounted,
} from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'

export default {
  props: {
    siteId: {
      required: true,
    },
  },
  setup(props) {
    const siteInfo = computed(() => store.state.sites.list.find(item => item.site_id === props.siteId) || {})
    const configData = reactive({
      new: true,
      site_id: props.siteId,
      solar_state: 0,
      solar_power: 0,
      batt_state: 0,
      batt_power: 0,
      batt_capacity: 0,
      discharge_soc: 0,
      charge_soc: 0,
      gen_state: 0,
      gen_power: 0,
      charge_power: 0,
      frequency: 0,
      time_condition: 0,
      charge_start_p1: '00:00',
      charge_stop_p1: '00:00',
      charge_start_p2: '00:00',
      charge_stop_p2: '00:00',
      soc_condition: 0,
      soc_start: 0,
      soc_stop: 0,
      fault_time_interval: 0,
      runtim_backup: 0,
      restime_backup: 0,
      over_voltage: 0,
      over_voltage_at: 0,
      under_voltage: 0,
      under_voltage_at: 0,
      over_current: 0,
      over_current_at: 0,
      pcs_fault: 0,
    })

    const form = ref(null)
    const isProcessing = ref(false)
    const alert = reactive({
      type: '',
      msg: '',
    })

    const fetchConfig = () => {
      isProcessing.value = true
      axios
        .get(`/microgrid/getconf?site_id=${props.siteId}`)
        .then(response => {
          const { code, message, data } = response.data
          if (code !== '0') {
            alert.type = 'error'
            alert.msg = message
          } else if (data.microgrid !== null) {
            configData.new = false
            configData.solar_state = data.microgrid.solar_state || 0
            configData.solar_power = data.microgrid.solar_power || 0
            configData.batt_state = data.microgrid.batt_state || 0
            configData.batt_power = data.microgrid.batt_power || 0
            configData.batt_capacity = data.microgrid.batt_capacity || 0
            configData.discharge_soc = data.microgrid.discharge_soc || 0
            configData.charge_soc = data.microgrid.charge_soc || 0
            configData.gen_state = data.microgrid.gen_state || 0
            configData.gen_power = data.microgrid.gen_power || 0
            configData.charge_power = data.microgrid.charge_power || 0
            configData.frequency = data.microgrid.frequency || 0
            configData.time_condition = data.microgrid.time_condition || 0
            configData.charge_start_p1 = data.microgrid.charge_start_p1 || '00:00'
            configData.charge_stop_p1 = data.microgrid.charge_stop_p1 || '00:00'
            configData.charge_start_p2 = data.microgrid.charge_start_p2 || '00:00'
            configData.charge_stop_p2 = data.microgrid.charge_stop_p2 || '00:00'
            configData.soc_condition = data.microgrid.soc_condition || 0
            configData.soc_start = data.microgrid.soc_start || 0
            configData.soc_stop = data.microgrid.soc_stop || 0
            configData.fault_time_interval = data.microgrid.fault_time_interval || 0
            configData.runtim_backup = data.microgrid.runtim_backup || 0
            configData.restime_backup = data.microgrid.restime_backup || 0
            configData.over_voltage = data.microgrid.over_voltage || 0
            configData.over_voltage_at = data.microgrid.over_voltage_at || 0
            configData.under_voltage = data.microgrid.under_voltage || 0
            configData.under_voltage_at = data.microgrid.under_voltage_at || 0
            configData.over_current = data.microgrid.over_current || 0
            configData.over_current_at = data.microgrid.over_current_at || 0
            configData.pcs_fault = data.microgrid.pcs_fault || 0
          }
        })
        .catch(() => {
          alert.type = 'error'
          alert.msg = 'Error while processing'
        })
        .finally(() => { isProcessing.value = false })
    }

    const submitChange = () => {
      alert.type = ''
      if (form.value.validate()) {
        isProcessing.value = true
        axios
          .post(configData.new ? '/microgrid/addconf' : '/microgrid/updateconf', {
            site_id: props.siteId,
            solar_state: configData.solar_state,
            solar_power: configData.solar_power,
            batt_state: configData.batt_state,
            batt_power: configData.batt_power,
            batt_capacity: configData.batt_capacity,
            discharge_soc: configData.discharge_soc,
            charge_soc: configData.charge_soc,
            gen_state: configData.gen_state,
            gen_power: configData.gen_power,
            charge_power: configData.charge_power,
            frequency: configData.frequency,
            time_condition: configData.time_condition,
            charge_start_p1: configData.charge_start_p1,
            charge_stop_p1: configData.charge_stop_p1,
            charge_start_p2: configData.charge_start_p2,
            charge_stop_p2: configData.charge_stop_p2,
            soc_condition: configData.soc_condition,
            soc_start: configData.soc_start,
            soc_stop: configData.soc_stop,
            fault_time_interval: configData.fault_time_interval,
            runtim_backup: configData.runtim_backup,
            restime_backup: configData.restime_backup,
            over_voltage: configData.over_voltage,
            over_voltage_at: configData.over_voltage_at,
            under_voltage: configData.under_voltage,
            under_voltage_at: configData.under_voltage_at,
            over_current: configData.over_current,
            over_current_at: configData.over_current_at,
            pcs_fault: configData.pcs_fault,
          })
          .then(response => {
            const { code, message } = response.data
            if (code !== '0') {
              alert.type = 'error'
              alert.msg = message
            } else {
              alert.type = 'success'
              alert.msg = 'Data saved!'
              configData.new = false
            }
          })
          .catch(() => {
            alert.type = 'error'
            alert.msg = 'Error while processing'
          })
          .finally(() => { isProcessing.value = false })
      }
    }

    onMounted(() => {
      store.dispatch('sites/fetchSites')
      fetchConfig()
    })

    return {
      siteInfo,
      configData,
      form,
      isProcessing,
      alert,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      submitChange,
      required,
    }
  },
}
</script>
